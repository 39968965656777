import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import store from './store'
import { UserProvider, defaultState as defaultStateUser, UserReducer } from './context/UserContext'
import { DataProvider, defaultState as defaultStateData, DataReducer } from './context/DataContext'
ReactDOM.render(
  <Provider store={store}>
    <UserProvider initialState={defaultStateUser} reducer={UserReducer}>
      <DataProvider initialState={defaultStateData} reducer={DataReducer}>
        <App /> 
      </DataProvider>
    </UserProvider>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
